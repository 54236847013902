<template>
  <div class="container">
    <!-- <b-message
      v-for="(item,index) in myItems"
      :title="item.title"
      :key="index"
      :closable="false"
      :type="index%2==0 ? 'is-primary' : 'is-success'"
    >{{item.value}}</b-message>-->

    <article v-for="(item,index) in firstItems" :key="index" class="message">
      <div class="message-header">
        <p>{{item.title}}</p>
      </div>
      <div class="message-body" v-html="item.value"></div>
    </article>

    <Adsense
      data-ad-client="ca-pub-8018925037718321"
      data-ad-slot="9453730685"
      data-ad-format="auto"
      :data-full-width-responsive="true"
      style="text-align: center; margin-bottom: 20px"
    ></Adsense>

    <article v-for="(item,index) in middleItems" :key="index+firstItems.length" class="message">
      <div class="message-header">
        <p>{{item.title}}</p>
      </div>
      <div class="message-body" v-html="item.value"></div>
    </article>

    <Adsense
      data-ad-client="ca-pub-8018925037718321"
      data-ad-slot="7358884536"
      data-ad-format="auto"
      :data-full-width-responsive="true"
      style="text-align: center; margin-bottom: 20px"
    ></Adsense>

    <article
      v-for="(item,index) in lastItems"
      :key="index+firstItems.length+middleItems.length"
      class="message"
    >
      <div class="message-header">
        <p>{{item.title}}</p>
      </div>
      <div class="message-body" v-html="item.value"></div>
    </article>

    <span />
  </div>
</template>

<script>
export default {
  name: "FAQ",
  data: function() {
    return {
      myItems: [
        {
          title: "Cosa fa l'applicazione?",
          value:
            "L'applicazione permette di caricare i movimenti bancari di una o più banche integrando informazioni finanziarie tramite questionari al fine di presentare reportistica di bilancio e analisi delle spese.<br/>L'applicazione permette di categorizzare in modo personalizzato i movimenti e consolidare più conti in un unico report. Tramite l'utilizzo di alcuni diagrammi i dati sono visualizzati in modo da permettere una facile comprensione."
        },
        {
          title: "Quanto costa?",
          value:
            "Il servizio è gratuito.<br/>Ci sosteniamo tramite inserti pubblicitari e sopratutto tramite una tua donazione. Per questi motivi, ti chiediamo gentilmente di non utilizzare adblockers."
        },
        {
          title: "Come caricare i dati?",
          value:
            "Innanzitutto, è necessario scaricare i movimenti dal servizio online della vostra banca. Una volta in possesso del documento Excel, recatevi all' \"Applicazione\" per poi cliccare sulla voce di menù \"Importa Excel\" opppure segui la procedura guidata sempre disponbile nel menù.<br/>E' possibile caricare più Excel di periodi sovrapposti o meno, l'applicazione si occuperà di caricare solo i nuovi dati o sostituire i movimenti non contabilizzati con la loro effettiva contabilizzazione.<br/>Se si caricano excel di più conti è presente una opzione che permette di indicare se sommare i saldi o utilizzare solo quello dell'ultimo excel caricato."
        },
        {
          title: "Quali formati sono supportati?",
          value:
            "Il caricamento dei movimenti avviene tramite Excel, usualmente esportato direttamente dalla banca online. La presentazione dei movimenti varia da banca a banca e l'applicazione effettua euristiche per un riconoscimento dei movimenti detto 'Smart Excel Detection'. I formati supportati sono xls, xlsx e odt."
        },
        {
          title: "Cosa fare se il file della mia banca non è riconosciuto?",
          value:
            "Se il file non è riconosciuto, prova ad aprirlo con <a href=\"https://it.libreoffice.org/\" target=\"_blank\" title=\"Suite di lavoro open-source\">LibreOffice</a> e salvarlo in formato ods.<br/>Se purtroppo il problema continua a sussistere, allora è possibile utilizzare il template fornito dall'applicazione. Provate a riportare i dati dall'Excel della banca al template (copia e incolla). E' possibile scaricare il template <a href=\"/ComeSto_Template_v1.0.xls\" title=\"Scarica template\">QUA</a>.<br/><br/>Inoltre, per aiutarci a migliorare il servizio, potete inviare ad <a class='mail' href='mailto:assistenza@comesto.biz' title=\"Contattaci\">assistenza@comesto.biz</a> l'excel non riconosciuto, avendo premura di rimuovere i vostri dati sensibili."
        },
        {
          title: "Come categorizzare i movimenti?",
          value:
            "Al fine di una utile analisi della spesa, è possibile categorizzare i singoli movimenti oppure applicare una regola basata sulla causale. Quest'ultima regola controlla tutti i movimenti ricercando la parola chiave dentro la causale e, se trovata, applica la categoria scelta al movimento.<br />Ogni movimento è categorizzabile cliccando sulla ultima colonna. Mentre, l'applicazione delle regole si può attuare cliccando su 'Categorizzazione massiva'."
        },
        {
          title: "Come vengono salvati i miei dati?",
          value:
            'I dati caricati vengono salvati dentro il tuo computer, in particolare nel tuo browser. L\'applicazione è in grado di funzionare totalmente con i dati locali e non necessità di essere conenssa ad internet. I tuoi dati non vengono trasmessi a nessun servizio esterno.<br/>Inoltre, per una maggiore riservatezza, i dati sono criptati e non leggibili in chiaro ed è disponibile una ulteriore funzionalità di protezione aggiuntiva che permette di proteggere i tuoi dati con l\'algoritmo <a href="https://it.wikipedia.org/wiki/Advanced_Encryption_Standard" target="_blank" title=\"Algorimo di crifratura\">AES</a> tramite una password da te fornita.<br><br>Opzionalmente, è possibile salvare i dati in Cloud più precisamente nel proprio spazio Google Drive. I dati sono accessibili solo da ‘Come stò’ e sono criptati con le stesse modalità indicate sopra.'
        },
        {
          title: "A chi sono visibili i miei dati?",
          value:
            "Il sito non può accedere remotamente ai tuoi dati. <br />Ai tali sono applicate le politiche di visibilità secondo le impostazioni del tuo browser. Cioè, di default, nessun altro sito può accedere ai dati di 'ComeSto'.<br /> Se necessario, è possibile cancellare i dati dalla apposita voce di menù."
        },
        {
          title: "Come leggere i grafici?",
          value:
            "Il report presenta in alto la vostra liquidità ed eventuali budget vincolati (ad esempio buoni pasto) oltre alla vostra situazione finanziaria. Quest'ultima, è calcolata sulla base di tutti i dati forniti e indica la \"salute\" della vostra situazione.<br/><br/>Sono riportati 3 grafici: <ul><li>1) i movimenti nell'arco del tempo per settimane/mesi/anni</li><li>2) la categorizzazione in valore assoluto e percentuale al fine di effettuare un'analisi delle spese</li><li>3) l'andamento di una categoria nel tempo</li></ul><br/> In fondo sono riportati dei report a carattere informativo. 'Budget Mese' per conoscere quanto incide ogni spese mensilmente e uno di curiosità e statistiche, in particolare è possibile vedere la percentuale di contanti sul totale e altre misurazioni."
        },
        {
          title: "Come vengono trattati rimborsi o movimenti fra più conti?",
          value:
            "Nel caso di rimborsi che insistono sullo stesso conto oppure di movimenti tra più conti, è possibile categorizzare tali movimenti con la stessa categoria. Più precisamente, le uscite e le entrate sono calcolate sommando i movimenti nella stessa categoria. In questo modo, i dati riportati sono informativi senza necessità di ulteriori calcoli.<br/> Esempio: in caso di più conti, uno principale ed uno per acquisti online, si avrà un bonifico dal conto 'principale' verso quello 'online' ed un'uscita da quello 'online' per aver acquistato su internet. L'unica uscita sarà l'acquisto online e non i movimenti interni fra conti. "
        }
      ]
    };
  },
  computed: {
    firstItems() {
      return this.myItems.slice(0, 3);
    },
    middleItems() {
      return this.myItems.slice(3, 8);
    },
    lastItems() {
      return this.myItems.slice(8);
    }
  }
};
</script>

<style scoped>
.message {
  text-align: justify;
}
</style>